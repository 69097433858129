@import 'styles/common.scss';

.container {
  height: 100%;
  position: relative;
  @include mobile {
    @include flex-column(center);
    padding: 40px 16px;
  }
  @include desktop {
    @include flex-center;
    background: var(--ui-teal-light-surface-tertiary);
    padding: 40px 48px;
  }
  .gradient1 {
    @include mobile {
      display: none;
    }
    @include desktop {
      position: absolute;
      top: -97px;
      border-radius: 787px;
      background: 
        rgba(0, 148, 145, 0.08);
      width: 787px;
      height: 192px;
      flex-shrink: 0;
      filter: blur(74px);
    }
  }
  .gradient2 {
    @include mobile {
      display: none;
    }
    @include desktop {
      position: absolute;
      left: 872px;
      top: 332px;
      border-radius: 379px;
      background: rgba(255, 217, 81, 0.12);
      width: 237px;
      height: 379px;
      flex-shrink: 0;
      filter: blur(125px);
    }
  }
  .gradient3 {
    @include mobile {
      display: none;
    }
    @include desktop {
      position: absolute;
      right: 225px;
      top: 468px;
      border-radius: 669px;
      background: #8AF9F7;
      width: 669px;
      height: 274px;
      filter: blur(125px);
      flex-shrink: 0;
      opacity: 0.1;
    }
  }
  .logo {
    @include desktop {
      position: absolute;
      top: 40px;
      left: 40px;
      cursor: pointer;
    }
  }

  .signin {
    width: 100%;
    z-index: 100;
  }
}
.hideMobile {
  @include mobile {
    display: none;
  }
}
.hideDesktop {
  @include desktop {
    display: none;
  }
}
